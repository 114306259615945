import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/OurWork.module.scss'

const OurWork = props => (
    <ScrollableAnchor id={'OurWork'}>
        <div className={styles.OurWork}>
            <p>
                Synaphea aims to be a valuable advisor for your needs, both
                strategically and technically, as we experiment and prototype
                practical use cases around blockchain.
            </p>
        </div>
    </ScrollableAnchor>
)

export default OurWork
