import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/OurStack.module.scss'

const OurStack = props => (
    <ScrollableAnchor id={'OurStack'}>
        <div className={styles.OurStack}>
            <h1>Our specialization</h1>
            <p>
                Everything we build is available to the following blockchain
                distributions and implementations
            </p>
            <div>
                <div>
                    <a href="https://www.hyperledger.org/">
                        <Img
                            resolutions={
                                props.HyperledgerLogo.childImageSharp
                                    .resolutions
                            }
                            style={{ height: '90px' }}
                            alt="Hyperledger logo"
                        />
                    </a>
                    <h3>Hyperledger</h3>
                </div>
                <div>
                    <a href="https://www.ethereum.org/">
                        <Img
                            resolutions={
                                props.EthereumLogo.childImageSharp.resolutions
                            }
                            style={{ height: '90px' }}
                            alt="Ethereum logo"
                        />
                    </a>
                    <h3>Ethereum</h3>
                </div>
                <div>
                    <a href="https://www.rsk.co/">
                        <Img
                            resolutions={
                                props.RootstockLogo.childImageSharp.resolutions
                            }
                            style={{ height: '90px' }}
                            alt="RSK logo"
                        />
                    </a>
                    <h3>Rootstock</h3>
                </div>
            </div>
        </div>
    </ScrollableAnchor>
)

export const awardFragment = graphql`
    fragment OurStackImages on Query {
        HyperledgerLogo: file(relativePath: { eq: "icons/hyperledger.png" }) {
            childImageSharp {
                resolutions(width: 90) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        EthereumLogo: file(relativePath: { eq: "icons/ethereum.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        RootstockLogo: file(relativePath: { eq: "icons/rootstock.png" }) {
            childImageSharp {
                resolutions(height: 90, width: 60, grayscale: true) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
    }
`

export default OurStack
