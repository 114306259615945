import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

import Banner from '../sections/banner'
import EasyAccess from '../sections/easy-access'
import UseCase from '../sections/use-case'
import OurStack from '../sections/our-stack'
import Team from '../sections/team'
import OurPlatform from '../sections/our-platform'
import OurWork from '../sections/our-work'
import Awards from '../sections/awards'
import Posts from '../sections/post'

const IndexPage = props => (
    <Layout>
        <Banner slide1={props.data.slide1} slide2={props.data.slide2} />
        <EasyAccess
            PlanningLogo={props.data.PlanningLogo}
            TerminalLogo={props.data.TerminalLogo}
            LayerLogo={props.data.LayerLogo}
        />
        <UseCase
            BankLogo={props.data.BankLogo}
            ShippingLogo={props.data.ShippingLogo}
            InsuranceLogo={props.data.InsuranceLogo}
            FinanceLogo={props.data.FinanceLogo}
            IotLogo={props.data.IotLogo}
            MedicineLogo={props.data.MedicineLogo}
        />
        <OurStack
            HyperledgerLogo={props.data.HyperledgerLogo}
            EthereumLogo={props.data.EthereumLogo}
            RootstockLogo={props.data.RootstockLogo}
        />
        <Team
            andreas={props.data.andreas.childImageSharp}
            jim={props.data.jim.childImageSharp}
            theofilis={props.data.theofilis.childImageSharp}
        />
        <OurPlatform PlatformImage={props.data.PlatformImage} />
        <OurWork />
        <Awards
            GroupamaImg={props.data.GroupamaImg}
            BFImg={props.data.BFImg}
            VivaImg={props.data.VivaImg}
            CrowdImg={props.data.CrowdImg}
        />
        <Posts articles={props.data.allMarkdownRemark.edges} />
    </Layout>
)

export const pageQuery = graphql`
    query IndexQuery {
        ...Slides
        ...EasyAccessImages
        ...UseCaseImages
        ...TeamImages
        ...AwardImages
        ...OurStackImages
        ...OurPlatformImages
        allMarkdownRemark(
            filter: { frontmatter: { category: { in: ["news"] } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                        subtitle
                        image {
                            childImageSharp {
                                sizes(maxWidth: 1200, quality: 80) {
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                    originalImg
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
