import React from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-animated-slider'
import ScrollableAnchor from 'react-scrollable-anchor'

import 'react-animated-slider/build/horizontal.css'
import '../assets/scss/modules/slider-animations.scss'

import styles from '../assets/scss/modules/Banner.module.scss'

let content = [
    {
        title:
            'Blockchain has changed the internet, and it will change the way we do business.',
        description:
            'Blockchain came to be known as the technology that enabled the creation of the bitcoin. It is a distributed database, with peerless, proven, assurances for its credibility, security and transparency.',
        button: 'Contact us',
    },
    {
        title: 'Enterprise Blockchain Solutions',
        description:
            'Synaphea is a company providing consulting services for blockchain for business along with B2B blockchain solutions. We aim to emphasize on the value such implementations could give to industries such as banking, insurance, shipping networks and many many more.',
        button: 'Contact Us',
    },
]

const Banner = props => {
    content = content.map((element, i) => {
        element.image = props['slide' + (i + 1)].childImageSharp.resolutions.src
        return element
    })

    return (
        <ScrollableAnchor id={'Home'}>
            <div id="Banner" className={styles.Banner}>
                <Slider className="slider-wrapper">
                    {content.map((item, index) => (
                        <div
                            key={index}
                            className="slider-content"
                            style={{
                                background: `url('${
                                    item.image
                                }') no-repeat center center`,
                            }}
                        >
                            <div className="inner">
                                <h1>{item.title}</h1>
                                <p
                                    style={{
                                        fontWeight: 400,
                                        lineHeight: '21px',
                                    }}
                                >
                                    {item.description}
                                </p>
                                <a href="/#Contact">
                                    <button>{item.button}</button>
                                </a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </ScrollableAnchor>
    )
}

export const bannerFragment = graphql`
    fragment Slides on Query {
        slide1: file(relativePath: { eq: "banner/slide-1.jpg" }) {
            childImageSharp {
                resolutions(
                    duotone: { highlight: "#39c0ba", shadow: "#000000" }
                    width: 1900
                    height: 1000
                ) {
                    ...GatsbyImageSharpResolutions
                }
            }
        }
        slide2: file(relativePath: { eq: "banner/slide-2-1.jpg" }) {
            childImageSharp {
                resolutions(
                    duotone: { highlight: "#39c0ba", shadow: "#000000" }
                    width: 1900
                    height: 1000
                ) {
                    ...GatsbyImageSharpResolutions
                }
            }
        }
    }
`

export default Banner
