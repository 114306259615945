import { values } from 'lodash'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/Team.module.scss'

import { team } from '../utils/team-info'

const Team = props => {
    team.jim.resolutions = props.jim.resolutions
    team.andreas.resolutions = props.andreas.resolutions
    team.george.resolutions = props.theofilis.resolutions

    return (
        <ScrollableAnchor id={'Team'}>
            <div className={styles.Team}>
                <h1>What we are made of</h1>
                <p>
                    This whole thing got started in 2015 and as rewarding as our
                    history has been, it’s the future we’re most excited about.
                    Each member of our team is here not only because they're
                    ridiculously talented--but because they love what they do.
                </p>
                <div>
                    {values(team)
                        .filter(c => c.isTeamMember)
                        .map((item, index) => (
                            <div key={index}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.linkdin}
                                >
                                    <Img
                                        resolutions={item.resolutions}
                                        alt={item.name}
                                    />
                                </a>
                                <h3>
                                    {item.name} <br />
                                    {item.role}
                                </h3>
                                <p>{item.bio}</p>
                            </div>
                        ))}
                </div>
            </div>
        </ScrollableAnchor>
    )
}
export const teamFragment = graphql`
    fragment TeamImages on Query {
        andreas: file(relativePath: { eq: "team/andreas.png" }) {
            childImageSharp {
                resolutions(width: 120, height: 120) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        jim: file(relativePath: { eq: "team/jim.png" }) {
            childImageSharp {
                resolutions(width: 120, height: 120) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        theofilis: file(relativePath: { eq: "team/theofilis.png" }) {
            childImageSharp {
                resolutions(width: 120, height: 120) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
    }
`

export default Team
