import React from 'react'

import { graphql } from 'gatsby'

import Img from 'gatsby-image'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/Awards.module.scss'

const Awards = props => {
    return (
        <ScrollableAnchor id={'Awards'}>
            <div className={styles.Awards}>
                <h1>Commendations and Awards</h1>
                <div>
                    <div>
                        <a href="http://www.hackinnow.gr/">
                            <Img
                                resolutions={
                                    props.GroupamaImg.childImageSharp
                                        .resolutions
                                }
                                style={{ height: '75px' }}
                                alt="Groupama"
                            />
                        </a>
                        <h3>1st winner team</h3>
                        <p>
                            Fintech hackathon sponsored by National Bank of
                            Greece
                        </p>
                    </div>
                    <div>
                        <a href="http://crowdhackathon.com/fintech/english/">
                            <Img
                                resolutions={
                                    props.CrowdImg.childImageSharp.resolutions
                                }
                                style={{ height: '75px', width: '75px' }}
                                alt="Crowdhackathon"
                            />
                        </a>
                        <h3>2nd winner team</h3>
                        <p>Insurance tech hackathon sponsored by Groupama</p>
                    </div>
                    <div>
                        <a href="http://befinnovative.com">
                            <Img
                                resolutions={
                                    props.BFImg.childImageSharp.resolutions
                                }
                                style={{ height: '75px' }}
                                alt="be finnovative"
                            />
                        </a>
                        <h3>be finnovative</h3>
                        <p />
                    </div>
                    <div>
                        <a href="http://idea.fintech.aueb.gr/">
                            <Img
                                resolutions={
                                    props.VivaImg.childImageSharp.resolutions
                                }
                                style={{ height: '75px' }}
                                alt="Viva nest"
                            />
                        </a>
                        <h3>VivaNEST</h3>
                        <p />
                    </div>
                </div>
            </div>
        </ScrollableAnchor>
    )
}

export const awardFragment = graphql`
    fragment AwardImages on Query {
        GroupamaImg: file(relativePath: { eq: "award/HackInnow1.png" }) {
            childImageSharp {
                resolutions(width: 120) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        BFImg: file(relativePath: { eq: "award/be-logo.png" }) {
            childImageSharp {
                resolutions(width: 120) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        VivaImg: file(relativePath: { eq: "award/viva1.png" }) {
            childImageSharp {
                resolutions(width: 120) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        CrowdImg: file(relativePath: { eq: "award/logo11.png" }) {
            childImageSharp {
                resolutions(width: 75) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
    }
`

export default Awards
