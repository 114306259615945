import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/EasyAccess.module.scss'

const EasyAccess = props => (
    <ScrollableAnchor id={'EasyAccess'}>
        <div className={styles.EasyAccess}>
            <h1>Easy Access</h1>
            <p>
                With Synaphea you can easily gain access to a variaty of tools
                and services to meet all your Blockchain needs.
            </p>
            <div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.LayerLogo.childImageSharp.resolutions
                            }
                            alt="Blockchain as a Service"
                        />
                    </a>
                    <h3>Blockchain as a Service</h3>
                    <p>
                        Smart Contracts are self-executing contractual states,
                        stored on the blockchain, which nobody controls and
                        therefore everyone can trust.
                    </p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.TerminalLogo.childImageSharp.resolutions
                            }
                            alt="Developing Tools"
                        />
                    </a>
                    <h3>Developing Tools</h3>
                    <p>
                        Blockchains are like Excel spreadsheets, but more secure
                        and there is no central authority needed to approve
                        transactions.
                    </p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.PlanningLogo.childImageSharp.resolutions
                            }
                            alt="Solution Developing"
                        />
                    </a>
                    <h3>Solution Developing</h3>
                    <p>
                        From initial design through to launch and operation of
                        production networks, we invest in deep ongoing
                        relationships with our partners at whatever stage
                        they’re at in their process.
                    </p>
                </div>
            </div>
        </div>
    </ScrollableAnchor>
)

export const easyAccessFragment = graphql`
    fragment EasyAccessImages on Query {
        PlanningLogo: file(relativePath: { eq: "icons/planning1.png" }) {
            childImageSharp {
                resolutions(width: 64) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        TerminalLogo: file(relativePath: { eq: "icons/terminal1.png" }) {
            childImageSharp {
                resolutions(width: 64) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        LayerLogo: file(relativePath: { eq: "icons/layers2.png" }) {
            childImageSharp {
                resolutions(width: 64) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
    }
`

export default EasyAccess
