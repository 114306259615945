import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/OurPlatform.module.scss'

const OurPlatform = props => (
    <ScrollableAnchor id={'OurPlatform'}>
        <div className={styles.OurPlatform}>
            <div>
                <div>
                    <h1>Our Platform</h1>
                    <p>
                        Synaphea is a team of blockchain professionals who
                        believe that core blockchain technology has many
                        potential applications in the enterprise. We are focused
                        on creating real-world applications that harness the
                        unique properties of the blockchain.
                    </p>
                    <p>
                        With our platform you gain access to a bundle of tools
                        that allows individuals or organisation to benefit from
                        Blockchain technology assuming little to no knowledge
                        and experience with programming.
                    </p>
                    <p>
                        Everything from creating your own Blockchain network to
                        creating and maintaining smartcontracts over a
                        Blockchain can easily be achieved through Synaphea
                        Dashboard.
                    </p>
                    <p>Engage with the blockchain now!</p>
                </div>
                <div>
                    <Img
                        alt="Our platform"
                        style={{ width: '100%' }}
                        outerWrapperClassName={'right-image'}
                        sizes={props.PlatformImage.childImageSharp.sizes}
                    />
                </div>
            </div>
        </div>
    </ScrollableAnchor>
)

export const ourPlatformFragment = graphql`
    fragment OurPlatformImages on Query {
        PlatformImage: file(relativePath: { eq: "icons/platform.png" }) {
            childImageSharp {
                sizes(maxWidth: 1900) {
                    ...GatsbyImageSharpSizes_withWebp
                }
            }
        }
    }
`

export default OurPlatform
