import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/UseCase.module.scss'

const UseCase = props => (
    <ScrollableAnchor id={'UseCase'}>
        <div className={styles.UseCase} alt="">
            <h1>Industries we touch</h1>
            <p>
                Blockchain promises to change the way industries as diverse as
                supply chain, healthcare, entertainment, financial services, and
                more, conduct business and execute transactions. Precisely how,
                and the pace at which, each of these industries adopts
                blockchain will surely vary.
            </p>
            <div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.BankLogo.childImageSharp.resolutions
                            }
                            style={{ height: '64px' }}
                            alt="Bank logo"
                        />
                    </a>
                    <h3>Banking</h3>
                    <p>
                        Payments are validated by a network of nodes/miners and
                        logged onto the immutable ledger, just like the Bitcoin
                        blockchain.
                    </p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.ShippingLogo.childImageSharp.resolutions
                            }
                            style={{ height: '64px' }}
                            alt="Shipping logo"
                        />
                    </a>
                    <h3>Shipping</h3>
                    <p>
                        Operating and financial efficiency benefits by enabling
                        them to identify the best possible counter-party,
                        utilizing the least company resources, at a
                        significantly lower cost than the traditional chartering
                        house.
                    </p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.InsuranceLogo.childImageSharp.resolutions
                            }
                            style={{ height: '64px' }}
                            alt="Insurance logo"
                        />
                    </a>
                    <h3>Insurance</h3>
                    <p>
                        Insurance companies will be able to provide temporal
                        liability insurance. Using smart contracts, an insurance
                        company could charge rates differently based on where
                        and under what conditions customers are operating.
                    </p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.FinanceLogo.childImageSharp.resolutions
                            }
                            style={{ height: '64px' }}
                            alt="Finance logo"
                        />
                    </a>
                    <h3>Finance</h3>
                    <p>
                        Decentralized venture capital fund that relies on a
                        wisdom-of-the-crowd voting system to make investment
                        decisions.
                    </p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.IotLogo.childImageSharp.resolutions
                            }
                            style={{ height: '64px' }}
                            alt="Iot logo"
                        />
                    </a>
                    <h3>Internet of Things</h3>
                    <p>A bridge between the blockchain and the real world.</p>
                </div>
                <div>
                    <a href="/#">
                        <Img
                            resolutions={
                                props.MedicineLogo.childImageSharp.resolutions
                            }
                            style={{ height: '64px' }}
                            alt="Medicine logo"
                        />
                    </a>
                    <h3>Healthcare</h3>
                    <p>A bridge between the blockchain and the real world.</p>
                </div>
            </div>
        </div>
    </ScrollableAnchor>
)

export const usecaseFragment = graphql`
    fragment UseCaseImages on Query {
        BankLogo: file(relativePath: { eq: "icons/bank.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        ShippingLogo: file(relativePath: { eq: "icons/shipping.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        InsuranceLogo: file(relativePath: { eq: "icons/insurance.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        FinanceLogo: file(relativePath: { eq: "icons/finance.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        IotLogo: file(relativePath: { eq: "icons/iot.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
        MedicineLogo: file(relativePath: { eq: "icons/medicine.png" }) {
            childImageSharp {
                resolutions(width: 60) {
                    ...GatsbyImageSharpResolutions_withWebp
                }
            }
        }
    }
`

export default UseCase
