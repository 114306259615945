import React from 'react'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import Slider from 'react-animated-slider'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from '../assets/scss/modules/PostList.module.scss'

const Posts = props => (
    <ScrollableAnchor id={'Posts'}>
        <div id="posts" className={styles.PostList}>
            <Slider className={`slider-wrapper`}>
                {props.articles.map((item, index) => (
                    <div
                        key={index}
                        className="slider-content"
                        style={{
                            background: `url('${
                                item.image
                            }') no-repeat center center`,
                        }}
                    >
                        <div className="inner">
                            <div className="left">
                                <h1>{item.node.frontmatter.title}</h1>
                                <p>{item.node.excerpt}</p>
                                <Link to={item.node.frontmatter.path}>
                                    <button>Read more</button>
                                </Link>
                            </div>
                            <div className="right">
                                <Img
                                    sizes={
                                        item.node.frontmatter.image
                                            .childImageSharp.sizes
                                    }
                                    alt="Cover blog"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    </ScrollableAnchor>
)

export default Posts
