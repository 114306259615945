import AndreasImg from '../assets/images/team/andreas.png'
import TheofilisImg from '../assets/images/team/theofilis.png'
import GiannakisImg from '../assets/images/team/jim.png'

export const team = {
    andreas: {
        key: 1,
        name: 'Andreas Paradise',
        linkdin: 'https://www.linkedin.com/in/andreasparadise/',
        bio:
            'IT strategist and leader. Passionate about Agile and Lean methodologies. He believes in  building teams that are passionate about innovation, applied research and delivering  business value to the highest quality.',
        shortbio:
            'IT strategist and leader. Passionate about Agile and Lean methodologies. He believes in  building teams that are passionate about innovation, applied research and delivering  business value to the highest quality.',
        role: 'CEO',
        img: AndreasImg,
        isTeamMember: true,
    },
    jim: {
        key: 2,
        name: 'Dimitris Giannakis',
        linkdin: 'https://www.linkedin.com/in/dimitrios-giannakis-04bb53138/',
        bio:
            'He has undertaken the task to create an ecosystem around of DLT and gains insider knowledge into the ways in which DLT can help in variety of  industries.',
        shortbio:
            'He has undertaken the task to create an ecosystem around of DLT and gains insider knowledge into the ways in which DLT can help in variety of  industries.',
        role: 'CBDO',
        img: GiannakisImg,
        isTeamMember: true,
    },
    george: {
        key: 3,
        name: 'George Theofilis',
        linkdin: 'https://www.linkedin.com/in/gtheofilis/',
        bio:
            'He has experience at designing production level software systems. He has been working with DLT authoring smart contracts and creating web-based front ends for smart contract systems.',
        shortbio:
            'He has experience at designing production level software systems. He has been working with DLT authoring smart contracts and creating web-based front ends for smart contract systems.',
        role: 'CTO',
        img: TheofilisImg,
        isTeamMember: true,
    },
}
